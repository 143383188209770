.article-product {

  .list-tab-nav-01 a{
    padding: 20px 40px;
  }
  .tab{
    table{
      margin: 10px 0 10px 0;
      th,td{
        padding: 6px;
      }
    }
    ol,ul{
      font-size: 85%;
    }
  }

  .ttl-cmn-01 {
    overflow: hidden;
  }
  .ttl-cmn-02 {
    overflow: hidden;
  }
  .box-text-image {
    overflow: hidden;
    margin-bottom: 30px;
    .fig-cmn-01 {
      position: relative;
      padding-top: 5px;
      z-index: 1;
      background-color: #fff;
      max-width: 450px;

      &.is-right {
          float: right!important;
          padding-left: 20px!important;
      }
      &.is-left {
          float: left!important;
          padding-right: 20px!important;
      }
      .txt-caption {
        margin-bottom: 5px;
        text-align: center;
      }
    }
    .detail {
      @media screen and (max-width: 768px){
        width: 100%;
        order: 1;
        margin-bottom: 15px;
      }
      p {
        line-height: 2;
      }
    }
  }
  .sec-products-01 {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .box-image {
    margin-bottom: 40px;
    text-align: center;

    img {
      max-width: 100%;
      margin: 0 auto;
    }
    &.full img {
      width: 100%;
      max-width: none;
    }
  }
  .list-cards {
    overflow: hidden;
    li {
      float: left;
      width: 25%;
      margin-top: 20px;
      padding: 0 5px;
      &:not(:last-child) {
        margin-bottom: 15px;
      }
      &:nth-child(4n+1) {
        clear: left;
      }
    }
    .image {
      overflow: hidden;
      position: relative;
      z-index: 1;
      margin-bottom: 15px;
      img { width: 100%; }
    }

    .title {
      font-size: 16px;
      font-size: 1.1em;
      margin-bottom: 10px;
      font-weight: 500;
    }
    .text {
      line-height: 2;
    }
  }
}
