/* article-quality
---------------------------------------------------------- */
.article-quality {
  p {
    color: #000;
    &:not(:last-child){
      margin-bottom: 20px;
    }
  }
  .ttl-cmn-01 {
    @media screen and (min-width: 769px){
      &:not(:last-child){
        margin-bottom: 25px;
      }
    }
  }
  .ttl-cmn-02 {
    @media screen and (min-width: 769px){
      &:not(:last-child){
        margin-bottom: 5px;
      }
    }
  }
  .main-image {
    @media screen and (min-width: 769px){
      &:not(:last-child){
        margin-bottom: 25px;
      }
    }
    @media screen and (max-width: 768px){
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    img {
      max-width: 100%;
    }
  }
  .box-text-image {
    &:not(:last-child){
      margin-bottom: 25px;
    }
    // @media screen and (min-width: 769px){
    overflow: hidden;
    .image {
      float: right;
    }
    .detail {
      padding-right: 20px;
      overflow: hidden;
    }
    // }
  }
  .list-number-01 {
    margin-bottom: 30px;
    color: #000;
    line-height: 2;
    li {
      &:before {
        color: #000;
      }
    }
  }


}
