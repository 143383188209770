@import "mixin";

@media print {

  /** Settings **/
  body {
    -webkit-print-color-adjust: exact;//chrome:背景画像設定
  }

  /** Layouts **/
  .header,
  .footer .footer-top, .footer .footer-bottom {
    display: none;
  }
  .wrapper {
    padding-top: 0;
  }
  .contents {
    position: relative;
    margin: 0 auto;
    #news &{
      margin: 0 auto 100px;
    }
  }
  .container {
    margin: 0 auto 100px;
    min-width: 1080px;
    width: 1080px;
    #news & {
      margin: 0 auto;
    }
  }
  .mod-tab-01{
    .container{
      margin: 0 auto;
    }
    &:last-child {
      margin: 0 auto 100px;
    }
  }

  /** GlobalNavigation **/
  .header-logo {
    top: 20px;
    .fixedheader & {
      top: 10px;
    }
  }

  .list-nav-global{
    display: none!important;
  }

  /** SubNavigation **/
  .contents-layout-01 .contents-sub {
    margin-bottom: 50px;
    width: 100%;
    height: 77.6136px!important;
  }

  .nav-local-01{
    border-top: 1px dotted #ccc!important;
    border-bottom: 1px dotted #ccc!important;
    .inner-wrapper-sticky{
      position:relative!important;
      top: initial!important;
      background-color: initial!important;
      box-shadow:  none!important;
    }

    &.is-affixed {
      .inner-wrapper-sticky {
        > ul {
          margin: 20px auto;
        }
      }
    }
  }//nav-local-01

  /* topPage */
  .list-card-links{
    figure {
      img {
        width: 269px!important;
      }
    }
  }

  /* ======================================================= */
  /* Components                                              */
  /* ======================================================= */

  /* article-product
  ---------------------------------------------------------- */
  @import "module/print/components/article_product";
  /* article-product
  ---------------------------------------------------------- */
  @import "module/print/components/article_quality";

  /* ======================================================= */
  /* Pages                                                   */
  /* ======================================================= */

/* ----------------------------------------------------------
    営業拠点
---------------------------------------------------------- */
    .mod-sales-base {
        .contact {
            .tel-sp {
                display: none;
            }
        }
    }
}
